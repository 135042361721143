<h1 *ngIf="col1" mat-dialog-title>Edit report title</h1>
<h1 *ngIf="col2" mat-dialog-title>Edit issue title</h1>
<h1 *ngIf="col3" mat-dialog-title>Remove issue:</h1>
<h1 *ngIf="col4" mat-dialog-title>Remove event from changelog:</h1>
<h1 *ngIf="col5" mat-dialog-title>Remove report:</h1>

<div mat-dialog-content >
  <div class="example-form">

      <mat-form-field *ngIf="col1" class="example-full-width" color="accent">
        <input [formControl]="reportname" type="text" matInput>
      </mat-form-field>

      <mat-form-field *ngIf="col2" class="example-full-width" color="accent">
        <input [formControl]="title" type="text" matInput>
      </mat-form-field>

      <h3 *ngIf="col3">{{data[1].item.title}}</h3>
      <h3 *ngIf="col4">{{data[1].item.desc}}</h3>
      <h3 *ngIf="col5">{{data[1].item.report_name}}</h3>
    </div>
</div>

<div mat-dialog-actions>
    <button *ngIf="col1" mat-raised-button color="accent" (click)="changereportname()">Save</button>
    <button *ngIf="col2" mat-raised-button color="accent" (click)="changeseckey()">Save</button>
    <button *ngIf="col5" mat-raised-button color="warn" (click)="removeReport(data[1].item)">Remove</button>
    <button *ngIf="col4" mat-raised-button color="warn" (click)="removechangelog(data[1].item)">Remove</button>
    <button *ngIf="col3" mat-raised-button color="warn" (click)="removeissue(data[1].item)">Remove</button>
    <button mat-raised-button color="primary" (click)="cancel()">Close</button>
</div>