<h1 class="mat-display-2">
  <mat-icon class="vertical-align-middle padding-bottom-3 size-45">playlist_add</mat-icon> Import report
</h1>

<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">

  <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">

    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar class="vertical-align-middle padding-bottom-3 size-45">description</mat-icon>
        <mat-card-title>Import from file (.VULNR)</mat-card-title>
        <mat-card-subtitle>(.VULNR) - File contains full report details</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <input cdkFocusInitial type="file" accept=".vulnr" (change)="onFileSelect($event.target)" name="myfiles" multiple>
      </mat-card-content>
      
    </mat-card>

  </div>

  <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
    <mat-card>

      <mat-card-header>
        <mat-icon mat-card-avatar class="vertical-align-middle padding-bottom-3 size-45">content_paste</mat-icon>
        <mat-card-title>Import from a clipboard</mat-card-title>
        <mat-card-subtitle>Import report from clipboard if need it.</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <mat-form-field appearance="outline" class="example-full-width" color="accent">
          <mat-label>Paste from the clipboard</mat-label>
          <textarea matInput rows="6" [(ngModel)]="pastefromclipboard" class="form-control z-depth-1"></textarea>
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions>
        <button (click)="importfromclipboard(pastefromclipboard)" mat-raised-button color="accent"><mat-icon class="vertical-align-middle padding-bottom-3 size-25">playlist_add</mat-icon> Import</button>
      </mat-card-actions>

      
    </mat-card>
  </div>

</div>