<h1 mat-dialog-title>Import issues from:</h1>
<div mat-dialog-content>

    <mat-form-field color="accent">
      <mat-label>Select source</mat-label>
      <mat-select [(value)]="selected_source" >
        <mat-option *ngFor="let Importsource of sour" [value]="Importsource.value">
          {{Importsource.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>&nbsp;&nbsp;&nbsp;

    <span *ngIf="selected_source == 'vulnrepojson'">
      <p><input type="file" accept=".vuln" name="myfile" (change)="fileChanged($event)"></p>
      <mat-form-field class="example-full-width" color="accent">
        <input cdkFocusInitial matInput placeholder="File security key" #pass [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
      
      <p *ngIf="vulnrepojsonplease_wait">Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></p>
      <p *ngIf="vulnrepowrongpass"><b style="color: red">Wrong security key!!!</b></p>
      <br>
      <button mat-raised-button color="accent" (click)="startUpload(pass.value)">Import</button>&nbsp;&nbsp;<button mat-raised-button color="primary" (click)="cancel()">Cancel</button>
    </span>

      <input *ngIf="selected_source == 'bugcrowd'" cdkFocusInitial type="file" accept=".csv" (change)="bugcrowdonFileSelect($event.target)" name="myfile">
      <p *ngIf="bugcrowdplease_wait">Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></p>

      <input *ngIf="selected_source == 'burp'" cdkFocusInitial type="file" accept=".xml" (change)="burponFileSelect($event.target)" name="myfile">
      <p *ngIf="burpplease_wait">Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></p>

      <input *ngIf="selected_source == 'nmap'" cdkFocusInitial type="file" accept=".xml" (change)="nmaponFileSelect($event.target)" name="myfile">
      <p *ngIf="nmapplease_wait">Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></p>

      <input *ngIf="selected_source == 'openvas'" cdkFocusInitial type="file" accept=".xml" (change)="openvas9onFileSelect($event.target)" name="myfile">
      <p *ngIf="openvas9please_wait">Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></p>

      <input *ngIf="selected_source == 'nessus'" cdkFocusInitial type="file" accept=".csv" (change)="onFileSelect($event.target)" name="myfile">
      <p *ngIf="please_wait">Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></p>

      <input *ngIf="selected_source == 'nessus_xml'" cdkFocusInitial type="file" accept=".nessus" (change)="nessusxmlonFileSelect($event.target)" name="myfile">
      <p *ngIf="nessusxmlplease_wait">Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></p>

  <br><button *ngIf="selected_source != 'vulnrepojson'" mat-raised-button color="primary" (click)="cancel()">Cancel</button>
</div>