
<h1 class="mat-display">
    <mat-icon class="vertical-align-middle size-45">https</mat-icon> Access API Vault
</h1>


<div md-dialog-content>

  <form class="example-form">

    <mat-form-field class="example-full-width" color="accent">
      <input matInput placeholder="API Vault password" #keypass (keydown)="onKeydown($event,keypass.value)" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>

  </form>

<div *ngIf="alert != ''">
  <span class="label danger"><mat-icon class="vertical-align-middle padding-bottom-3 size-25">warning</mat-icon> {{alert}}</span><br>
</div>
<mat-checkbox [checked]="removedialog" (change)="removedialogFunc($event)">Do not show again in this session</mat-checkbox>
</div>
<br>
<div md-dialog-actions>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">
    <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="start end">
      <button mat-flat-button color="accent" (click)="decrypt(keypass.value)"><mat-icon>lock_open</mat-icon> Open vault</button>
    </div>
    <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="end start">
      <button mat-flat-button color="primary" (click)="cancel()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
  </div>
</div>


