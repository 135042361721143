
<div fxLayout="row wrap" fxLayoutGap="10px grid">
  <div fxLayoutAlign="center center" fxFlex="10%" fxFlex.sm="100%" fxFlex.xs="100%">
    <img title="VULNRΞPO REPORT" width="60px" src="assets/issue.png">
  </div>
  <div fxLayoutAlign="start center" fxFlex="90%" fxFlex.sm="100%" fxFlex.xs="100%">
    <h1 style="margin: 0 0 0;" class="mat-display-2">{{reportdesc?.report_name}} <a
      *ngIf="decryptedReportDataChanged" title="Edit report name" [routerLink]="[]" (click)="editreporttitle(reportdesc)">
      <mat-icon class="vertical-align-middle valign-center padding-bottom-3">edit</mat-icon></a>
  </h1>
  </div>
</div>

<br><br>
<!-- Report info -->
<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>fingerprint</mat-icon>
    </div>
    <mat-card-title style="display: flex">Report information
      &nbsp;
      <button *ngIf="decryptedReportDataChanged" mat-icon-button style="width: 25px; height: 25px; flex-shrink: 0; line-height: 5px; border-radius: 70%;" [matMenuTriggerFor]="reportsetting">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #reportsetting="matMenu">
          <a [routerLink]="[]" (click)="changesecuritykey(reportdesc.report_id)" class="active-link" title="Change report security key" mat-menu-item>
            <mat-icon class="vertical-align-middle padding-bottom-3">security</mat-icon> Change report securty key
          </a>
      </mat-menu>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row wrap" fxLayoutGap="10px grid">

      <div fxFlex="50%" fxFlex.sm="50%" fxFlex.xs="100%">
        <p>Created: {{reportdesc?.report_createdate | date:'medium'}}</p>
        <p *ngIf="reportdesc?.report_lastupdate">Last Update: {{reportdesc?.report_lastupdate | date:'medium'}}</p>
        <p>Report ID: {{reportdesc?.report_id}}</p>
        <p *ngIf="decryptedReportDataChanged">Report Version: <b>V.{{decryptedReportDataChanged?.report_version}}</b></p>
        <p *ngIf="report_decryption_in_progress">Report is decrypted please wait...<mat-progress-bar style="width: 300px;" color="accent" mode="indeterminate"></mat-progress-bar></p>
        <p *ngIf="report_encryption_in_progress">Report is encrypted please wait...<mat-progress-bar style="width: 300px;" color="accent" mode="indeterminate"></mat-progress-bar></p>
      </div>
  
      <div *ngIf="decryptedReportDataChanged" style="text-align: center;" fxFlex="50%" fxFlex.sm="50%" fxFlex.xs="100%">

          <span *ngIf="reportdesc?.report_lastupdate !== '' && reportdesc?.report_lastupdate !== 0 && lastsavereportdata == ''">
            {{savemsg}}<br>Last save: <b><i>{{reportdesc?.report_lastupdate | date: 'medium'}}</i></b>
          </span>
          <span *ngIf="lastsavereportdata !== ''">
            {{savemsg}}<br>Last save: <b class="active-link"><i>{{lastsavereportdata | date: 'medium'}}</i></b>
          </span>

        <p>
          <button mat-raised-button color="primary" (click)="saveReportChanges(reportdesc.report_id)">
            <mat-icon class="vertical-align-middle padding-bottom-3">save</mat-icon> Save all changes and release version!
          </button>
        </p>
        <span *ngIf="youhaveunsavedchanges">
          <mat-icon class="vertical-align-middle padding-bottom-3">notifications_active</mat-icon> You have unsaved changes! <mat-icon class="vertical-align-middle padding-bottom-3" color="accent">arrow_upward</mat-icon>
        </span>
        
      </div>
  
    </div>


  </mat-card-content>
</mat-card>

<div *ngIf="decryptedReportDataChanged">

  <br>
  <!-- Report details -->
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>description</mat-icon>
      </div>
      <mat-card-title>Report details</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div fxLayout="row wrap" fxLayoutGap="10px grid">

        <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
          <mat-form-field appearance="outline" class="example-full-width" color="accent">
            <mat-label>Scope</mat-label>
            <textarea matInput rows="6" [(ngModel)]="decryptedReportDataChanged.report_scope"
              value="{{decryptedReportDataChanged?.report_scope}}"></textarea>
            <mat-hint>You can use Markdown or HTML. 
              <a class="active-link" href="javascript:void(0);" (click)="addtablescope()" title="Insert table to scope.">[T]</a>&nbsp;
              <a class="active-link" href="javascript:void(0);" (click)="addcodescope()" title="Insert code to scope.">[C]</a>
            </mat-hint>
          </mat-form-field>
        </div>
    
        <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">

          <div fxLayout="row wrap" fxLayoutGap="10px grid">

              <div fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">
                <mat-form-field appearance="outline" class="example-full-width" color="accent">
                  <mat-label>Report summary comment</mat-label>
                  <textarea matInput rows="1" [(ngModel)]="decryptedReportDataChanged.report_summary"
                    value="{{decryptedReportDataChanged?.report_summary}}"></textarea>
                </mat-form-field>
              </div>
          
              <div fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">

                <div fxLayout="row wrap" fxLayoutGap="10px grid">

                  <div fxFlex="50%" fxFlex.sm="50%" fxFlex.xs="100%">
                    <mat-form-field class="example-full-width" color="accent">
                      <input matInput [matDatepicker]="picker" placeholder="Research start date"
                        [(ngModel)]="decryptedReportDataChanged.report_metadata.starttest">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
              
                  <div fxFlex="50%" fxFlex.sm="50%" fxFlex.xs="100%">
                    <mat-form-field class="example-full-width" color="accent">
                      <input matInput [matDatepicker]="pickerend" placeholder="Research end date"
                        [(ngModel)]="decryptedReportDataChanged.report_metadata.endtest">
                      <mat-datepicker-toggle matSuffix [for]="pickerend"></mat-datepicker-toggle>
                      <mat-datepicker #pickerend></mat-datepicker>
                    </mat-form-field>
                  </div>
              
              </div>

              </div>
        
          </div>

        </div>
    
      </div>

    </mat-card-content>
  </mat-card>


  <br>
  <!-- Issues list -->
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>bug_report</mat-icon>
      </div>
      <mat-card-title class="card-container-left">
        <span matBadge="{{decryptedReportDataChanged.report_vulns.length}}" matBadgeOverlap="false">Issues</span>
      </mat-card-title>
      <mat-card-title class="card-container-right">

        <div fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="flex-end">

          <div fxFlex.xs="100%">
            <button style="width: 100%;" mat-button (click)="import_issues()">
              <mat-icon class="vertical-align-middle padding-bottom-3">playlist_add</mat-icon>Import issues
            </button>
          </div>
      
          <div fxFlex.xs="100%">

            <mat-button-toggle-group>
              <mat-button-toggle class="split-button-1">

                <button style="width: 80%;" *ngIf="decryptedReportDataChanged.report_vulns.length != 0" mat-button
            (click)="export_issues(selected3, decryptedReportDataChanged.report_vulns)">
            <mat-icon class="vertical-align-middle padding-bottom-3">arrow_downward</mat-icon>Export issues</button>

              </mat-button-toggle>

              <mat-button-toggle [matMenuTriggerFor]="dropdownMenuOne">

                <button class="exportbutton" *ngIf="decryptedReportDataChanged.report_vulns.length != 0" mat-button><mat-icon>arrow_drop_down</mat-icon></button>

              </mat-button-toggle>
            </mat-button-toggle-group>
            
            <mat-menu #dropdownMenuOne="matMenu">
              <button mat-menu-item [matMenuTriggerFor]="bysev">Export by Severity</button>
            </mat-menu>

            <mat-menu #bysev="matMenu">
              <button mat-menu-item (click)="export_by_severity(decryptedReportDataChanged.report_vulns, 'Critical')">Critical</button>
              <button mat-menu-item (click)="export_by_severity(decryptedReportDataChanged.report_vulns, 'High')">High</button>
              <button mat-menu-item (click)="export_by_severity(decryptedReportDataChanged.report_vulns, 'Medium')">Medium</button>
              <button mat-menu-item (click)="export_by_severity(decryptedReportDataChanged.report_vulns, 'Low')">Low</button>
              <button mat-menu-item (click)="export_by_severity(decryptedReportDataChanged.report_vulns, 'Info')">Info</button>
            </mat-menu>
          </div>
      
          <div fxFlex.xs="100%">

            <button *ngIf="decryptedReportDataChanged.report_vulns.length > 1" mat-button [matMenuTriggerFor]="menu" color="accent"><mat-icon class="vertical-align-middle padding-bottom-3">swap_vert</mat-icon> Sort by</button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="sortbyseverity()">Severity</button>
              <button mat-menu-item (click)="sortbycvss()">CVSS</button>
            </mat-menu>

          </div>
      
      </div>

      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)" multi="true">

        <mat-expansion-panel class="mat-elevation-z9"
          *ngFor="let dec_data of decryptedReportDataChanged.report_vulns; let i=index" (opened)="panelOpenState = true"
          (closed)="panelOpenState = false" cdkDrag>


          <mat-expansion-panel-header cdkDragHandle>
            <mat-panel-title fxLayoutAlign="start center">
              <div style="display: block ruby;">
              <mat-checkbox style="margin-left: 5px;" [(ngModel)]="selected3[i]" (change)="toggle()" (click)="$event.stopPropagation()"></mat-checkbox>&nbsp;&nbsp;
              <span
                class="label {{dec_data.severity | lowercase}}">{{dec_data.severity}}</span>&nbsp;&nbsp;{{dec_data.title}}
              <a *ngIf="decryptedReportDataChanged" title="Edit issue title" [routerLink]="[]"
                (click)="editissuetitle(dec_data);$event.stopPropagation();">
                <mat-icon class="vertical-align-middle valign-issues-center padding-bottom-3">edit</mat-icon>
              </a>
            </div>
            </mat-panel-title>
            <mat-panel-description fxLayoutAlign="center center">
              <span style="pointer-events:none;">
                <mat-chip-list>
                  <mat-chip *ngFor="let tag of dec_data.tags" [selectable]="false" ngDefaultControl>
                    {{tag.name}}
                  </mat-chip>
                </mat-chip-list> 
              </span>
              &nbsp; {{ (dec_data.cve.length>30) ? (dec_data.cve | slice:0:30)+'..':(dec_data.cve) }}
            </mat-panel-description>

            <mat-panel-description *ngIf="dec_data?.status" fxLayoutAlign="end center">
              <div *ngIf="dec_data?.bounty">
                <mat-icon *ngIf="dec_data?.bounty.length > 0" title="Found bug-bounty program related to the issue" color="accent">attach_money</mat-icon>
              </div>
              <div *ngIf="dec_data?.status === 1">
                <mat-icon style="color:red" title="Issue status: Open (Waiting for review)" class="vertical-align-middle valign-issues-center padding-bottom-3">report</mat-icon>
              </div>
              <div *ngIf="dec_data?.status === 2">
                <mat-icon style="color:yellow" title="Issue status: Fix In Progress" class="vertical-align-middle valign-issues-center padding-bottom-3">warning</mat-icon>
              </div>
              <div *ngIf="dec_data?.status === 3">
                <mat-icon style="color:green" title="Issue status: Fixed" class="vertical-align-middle valign-issues-center padding-bottom-3">check_circle</mat-icon>
              </div>
              <div *ngIf="dec_data?.status === 4">
                <mat-icon style="color:grey" title="Issue status: Won't Fix" class="vertical-align-middle valign-issues-center padding-bottom-3">check_circle</mat-icon>
              </div>
            </mat-panel-description>

          </mat-expansion-panel-header>
          <mat-expansion-panel-header *cdkDragPlaceholder></mat-expansion-panel-header>

          <div fxLayout="row wrap" fxLayoutGap="10px grid">

            <div fxFlex="70%" fxFlex.sm="70%" fxFlex.xs="100%">
              <mat-form-field appearance="outline" class="example-full-width" color="accent">
                <mat-label>Proof of Concept</mat-label>
                <textarea [(ngModel)]="dec_data.poc" (change)="changePoC(dec_data)" matInput rows="8"></textarea>
              </mat-form-field>
            </div>
        
            <div fxFlex="30%" fxFlex.sm="30%" fxFlex.xs="100%">


              <div fxLayout="row wrap" fxLayoutGap="10px grid">

                <div fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">
                  <mat-form-field class="example-full-width" color="accent">
                    <mat-select placeholder="Severity" [(value)]="dec_data.severity" [ngModel]="dec_data.severity" (ngModelChange)="doStats()">
                      <mat-option *ngFor="let sev of dataSourceforseverity" [value]="sev.severity">
                        {{sev.severity}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">
                  <mat-form-field class="example-full-width" color="accent">
                    <mat-select placeholder="Issue status" [(value)]="dec_data.status" [ngModel]="dec_data.status" (ngModelChange)="doStats()">
                      <mat-option *ngFor="let issuestat of issueStatus" [value]="issuestat.value">
                        {{issuestat.status}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">
                  <mat-form-field class="example-full-width" color="accent">
                    <input matInput [matDatepicker]="picker" placeholder="Find date" [(ngModel)]="dec_data.date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>

            </div>

            </div>
        
        </div>

        <div fxLayout="row wrap" fxLayoutGap="10px grid">

          <div fxLayout="row wrap" fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">

            <div fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">
              <mat-form-field appearance="outline" class="example-full-width" color="accent">
                <mat-label>Description</mat-label>
                <textarea [(ngModel)]="dec_data.desc" matInput rows="6"></textarea>
              </mat-form-field>
            </div>
  
              <div *ngIf="dec_data.tags" fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">
                <mat-form-field appearance="outline" class="example-tags-list" color="accent">
                  <mat-label>TAGs</mat-label>
                  <mat-chip-list #tagList aria-label="Tags">
                    <mat-chip *ngFor="let tag of dec_data.tags" [(ngModel)]="dec_data.tags" [selectable]="selectable"
                             [removable]="removable" (removed)="TAGremove(tag, dec_data)" ngDefaultControl>
                      {{tag.name}}
                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="New tag..."
                           [matChipInputFor]="tagList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           [matChipInputAddOnBlur]="addOnBlur"
                           (matChipInputTokenEnd)="TAGadd($event, dec_data)">
                  </mat-chip-list>
                </mat-form-field>
              </div>

          </div>
          
          <div fxFlex="50%" fxFlex.sm="100%"  fxFlex.xs="100%">

            <div fxLayout="row wrap" fxLayoutGap="10px grid">

              <div fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">
                <mat-form-field appearance="outline" class="example-full-width" color="accent">
                  <mat-label>References</mat-label>
                  <textarea [(ngModel)]="dec_data.ref" matInput rows="2"></textarea>
                </mat-form-field>
              </div>
          
              <div fxFlex="50%" fxFlex.sm="50%" fxFlex.xs="100%">
                <mat-form-field class="example-full-width" color="accent">
                  <input matInput placeholder="CVSS" [(ngModel)]="dec_data.cvss" value="{{dec_data.cvss}}">
                  <button mat-button (click)="openDialogCVSS(dec_data)" style="line-height: 25px;" color="accent" matSuffix><mat-icon matSuffix>add_circle_outline</mat-icon> CVSS calculator</button>
                </mat-form-field>
              </div>

              <div fxFlex="50%" fxFlex.sm="50%" fxFlex.xs="100%">
                <mat-form-field class="example-full-width" color="accent">
                  <input matInput placeholder="CVE" [(ngModel)]="dec_data.cve" value="{{dec_data.cve}}">
                  <button mat-button (click)="openDialogCVE(dec_data)" style="line-height: 25px;" color="accent" matSuffix><mat-icon matSuffix>explore</mat-icon> CVE Info</button>
                </mat-form-field>
              </div>


              <div fxLayout="row wrap" fxLayoutGap="5px grid" *ngIf="dec_data?.bounty" fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">

                <div style="display: inline;text-align: end;" fxFlex="80%" fxFlex.sm="80%" fxFlex.xs="100%">
                  <div *ngIf="BBmsg != ''">
                    <p>{{BBmsg}}<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></p>
                  </div>
                  <button *ngFor="let bounty of dec_data?.bounty" mat-stroked-button color="accent" (click)="redirectBounty(bounty?.url)" title="{{bounty?.url}}"><mat-icon>attach_money</mat-icon>{{bounty?.name}}</button>
                </div>

                <div fxLayoutAlign="end start" fxFlex="20%" fxFlex.sm="20%" fxFlex.xs="100%">
                  <button mat-raised-button color="primary" matBadge="{{dec_data?.bounty.length}}" matBadgeColor="accent" (click)="searchBounty(dec_data)" title="Search for bug bounty program">
                    <mat-icon>attach_money</mat-icon><mat-icon>search</mat-icon> 
                  </button>
                </div>
                
              </div>
          
          </div>

          </div>
      
      </div>


          <mat-action-row align="end">


            <button mat-fab (click)="fileuploadInput.click()" color="primary" title="Upload file">
              <i class="material-icons">attach_file</i>
              <input type="file" #fileuploadInput id="file" accept=".*" (change)="uploadAttach($event.target, dec_data)"
                style="display:none;">
            </button>

            <div fxFlex></div>

            <span align="start" *ngIf="upload_in_progress">Upload please wait...<mat-progress-bar style="width: 300px;" color="accent" mode="indeterminate"></mat-progress-bar></span>

            <div fxFlex></div>
            
            <button mat-button color="warn" (click)="removeIssiue(dec_data)">
              <mat-icon class="vertical-align-middle padding-bottom-3" matSuffix>remove_circle</mat-icon> REMOVE
            </button>

          </mat-action-row>

          <div fxLayout="row wrap" fxLayout.xs="column wrap" fxLayoutGap="10px grid">
            <div *ngFor="let key of dec_data.files">

              <div *ngIf="key.type.includes('image')" class="mat-elevation-z4" style="padding: 5px; text-align: center;">
                <img [src]="key.data" title="{{key.title}}" width="200px" height="100px"><br>
                <a [routerLink]="[]" (click)="downloadAttach(key.data, key.title)" title="Download file">
                  <mat-hint><mat-icon class="vertical-align-middle padding-bottom-3" matSuffix>get_app</mat-icon> {{key.title}}</mat-hint>
                </a>&nbsp;<a style="float: right;" [routerLink]="[]" (click)="removeAttach(key, dec_data)"
                  title="Remove file">
                  <mat-icon style="color: red">delete_forever</mat-icon>
                </a>
              </div>
              <div *ngIf="!key.type.includes('image')" class="mat-elevation-z4" style="padding: 5px; text-align: center;">
                <img src="/assets/file.png" title="{{key.title}}" width="80px" height="100px"><br>
                <a [routerLink]="[]" (click)="downloadAttach(key.data, key.title)" title="Download file">
                  <mat-hint><mat-icon class="vertical-align-middle padding-bottom-3" matSuffix>get_app</mat-icon> {{key.title}}</mat-hint>
                </a>&nbsp;<a style="float: right;" [routerLink]="[]" (click)="removeAttach(key, dec_data)"
                  title="Remove file">
                  <mat-icon style="color: red">delete_forever</mat-icon>
                </a>
              </div>
            </div>
          </div>

        </mat-expansion-panel>

      </mat-accordion>
      <br>
      <div *ngIf="pok == 1"><a class="active-link" [routerLink]="[]" (click)="selectall()">Select all</a> / <a
          class="active-link" [routerLink]="[]" (click)="deselectall()">Unselect all</a>&nbsp;<button
          title="Remove selected items" mat-icon-button color="warn" (click)="removeSelecteditems(selected3)">
          <mat-icon>delete_forever</mat-icon>
        </button></div>

      <div class="banner">
        <div class="center-me"><button class="mat-elevation-z9" mat-raised-button color="accent" (click)="addissue()">
            <mat-icon class="vertical-align-middle padding-bottom-3">add</mat-icon> Add custom issue
          </button></div>
      </div>
    </mat-card-content>

  </mat-card>

  <br>
  <!-- Stats -->
  <mat-card *ngIf="decryptedReportDataChanged.report_vulns.length !== 0">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>insert_chart</mat-icon>
      </div>
      <mat-card-title>Statistics</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div fxLayout="row wrap" fxLayoutGap="10px grid">

        <div fxFlex="50%" fxFlex.sm="40%" fxFlex.xs="100%">
          <table mat-table [dataSource]="dataSourceforseverity" id="stats" class="mat-elevation-z8">

            <!-- Severity Column -->
            <ng-container matColumnDef="severity">
              <th mat-header-cell *matHeaderCellDef> Severity </th>
              <td mat-cell *matCellDef="let element"> <span
                  class="label {{element.severity | lowercase}}">{{element.severity}}</span> </td>
              <td mat-footer-cell *matFooterCellDef> Total </td>
            </ng-container>

            <!-- Count Column -->
            <ng-container matColumnDef="count">
              <th mat-header-cell *matHeaderCellDef> Number </th>
              <td mat-cell *matCellDef="let element"> {{element.count}} </td>
              <td mat-footer-cell *matFooterCellDef> {{ decryptedReportDataChanged.report_vulns.length }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedSeverityColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedSeverityColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedSeverityColumns"></tr>
          </table>
        </div>
    
        <div fxFlex="50%" fxFlex.sm="60%" fxFlex.xs="100%">
          <div style="display: block">
            <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [colors]="chartcolors"
              [chartType]="pieChartType" (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)"></canvas>
          </div>
        </div>
    
    </div>

    </mat-card-content>
  </mat-card>

  <br>
  <!-- Researcher -->
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>face</mat-icon>
      </div>
      <mat-card-title>Researcher&nbsp;
        <button mat-icon-button style="width: 25px; height: 25px; flex-shrink: 0; line-height: 5px; border-radius: 70%;" [matMenuTriggerFor]="researchermenu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #researchermenu="matMenu">
          <a [routerLink]="[]" (click)="addresearcher()" class="active-link" title="Add new researcher" mat-menu-item><mat-icon class="vertical-align-middle">add</mat-icon> Add researcher</a>
        </mat-menu>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div *ngFor="let researchers of decryptedReportDataChanged.researcher" fxLayout="row wrap" fxLayoutGap="10px grid">
        <div fxFlex="24%" fxFlex.sm="49%" fxFlex.xs="99%">
          <mat-form-field class="example-full-width" color="accent">
            <input matInput placeholder="Name" [(ngModel)]="researchers.reportername"
              value="{{researchers.reportername}}">
            <mat-icon matSuffix>person_pin</mat-icon>
          </mat-form-field>
        </div>

        <div fxFlex="24%" fxFlex.sm="49%" fxFlex.xs="99%">
          <mat-form-field class="example-full-width" color="accent">
            <input matInput placeholder="E-mail" [(ngModel)]="researchers.reporteremail"
              value="{{researchers.reporteremail}}">
            <mat-icon matSuffix>email</mat-icon>
          </mat-form-field>
        </div>
        
        <div fxFlex="24%" fxFlex.sm="49%" fxFlex.xs="99%">
          <mat-form-field class="example-full-width" color="accent">
            <input matInput placeholder="Social links"
              [(ngModel)]="researchers.reportersocial"
              value="{{researchers.reportersocial}}">
            <mat-icon matSuffix>share</mat-icon>
          </mat-form-field>
        </div>
        
        <div fxFlex="24%" fxFlex.sm="44%" fxFlex.xs="94%">
          <mat-form-field class="example-full-width" color="accent">
            <input matInput placeholder="Website" [(ngModel)]="researchers.reporterwww"
              value="{{researchers.reporterwww}}">
            <mat-icon matSuffix>public</mat-icon>
          </mat-form-field>
        </div>

        <div fxFlex="4%" fxFlex.sm="4%" fxFlex.xs="4%">
          <button mat-icon-button color="warn" (click)="removeresearcher(researchers)" title="Remove researcher">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>

      </div>

    </mat-card-content>



  </mat-card>

  <br>
  <!-- changelog -->
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>track_changes</mat-icon>
      </div>
      <mat-card-title style="display: flex">Changelog
        &nbsp;
        <button mat-icon-button style="width: 25px; height: 25px; flex-shrink: 0; line-height: 5px; border-radius: 70%;" [matMenuTriggerFor]="changelogmenu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #changelogmenu="matMenu">
          <a [routerLink]="[]" (click)="addchangelog()" class="active-link" title="Add event to changelog" mat-menu-item><mat-icon class="vertical-align-middle">add</mat-icon> Add event to changelog</a>
        </mat-menu>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <table *ngIf="listchangelog != 0" mat-table [dataSource]="dataSource" class="changelogTab" matSort matSortActive="date"
        matSortDirection="desc" class="mat-elevation-z8">

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
          <td mat-cell *matCellDef="let element"> {{element.date | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="desc">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
        </ng-container>

        <ng-container matColumnDef="settings">
          <th mat-header-cell *matHeaderCellDef> <mat-icon title="Settings">settings</mat-icon> </th>
          <td mat-cell *matCellDef="let element">

            <button mat-icon-button style="width: 25px; height: 25px; flex-shrink: 0; line-height: 5px; border-radius: 70%;" [matMenuTriggerFor]="changelogsettings"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #changelogsettings="matMenu">
              <a [routerLink]="[]" (click)="editchangelog(element)" class="active-link" title="Edit event" mat-menu-item><mat-icon class="vertical-align-middle">edit</mat-icon> Edit event</a>
              <a [routerLink]="[]" (click)="removefromchangelog(element)" class="active-link" title="Remove event" mat-menu-item><mat-icon class="vertical-align-middle">delete_forever</mat-icon> Remove event</a>
            </mat-menu>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div fxLayout="row wrap" fxLayoutGap="10">
        <div fxFlex="30%" fxFlex.sm="20%" fxFlex.xs="10%">

          <div *ngIf="listchangelog == 0">
            <p>Your changelog list is empty :-( </p>
          </div>

        </div>

        <div fxFlex="70%" fxFlex.sm="80%" fxFlex.xs="90%" *ngIf="listchangelog != 0">
          <p>
            <mat-paginator [pageSizeOptions]="[5, 15, 30]" showFirstLastButtons></mat-paginator>
          </p>
        </div>

      </div>

    </mat-card-content>
  </mat-card>
  <br>

  <!-- Report Settings -->
  <mat-card *ngIf="decryptedReportDataChanged">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>settings</mat-icon>
      </div>

      <mat-card-title style="display: flex" fxLayoutAlign="start">Report Settings 
        &nbsp;
        <button mat-icon-button style="width: 25px; height: 25px; flex-shrink: 0; line-height: 5px; border-radius: 70%;" [matMenuTriggerFor]="Settingsmenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #Settingsmenu="matMenu">
            <a [routerLink]="[]" (click)="addCustomcontent(decryptedReportDataChanged)" class="active-link" title="Add custom content to report" mat-menu-item>
              <mat-icon class="vertical-align-middle padding-bottom-3">add_circle</mat-icon> Add custom content to report
            </a>
            <button *ngIf="ReportProfilesList.length > 0" mat-menu-item [matMenuTriggerFor]="reportProfiles">Select settings profile</button>
        </mat-menu>
        <mat-menu #reportProfiles="matMenu">
          <span *ngFor="let profile of ReportProfilesList">
            <button mat-menu-item (click)="setReportProfile(profile)">{{profile.profile_name}}</button>
          </span>
        </mat-menu>
      </mat-card-title>

    </mat-card-header>
    <mat-card-content>


      <mat-tab-group [selectedIndex]="0" dynamicHeight color="accent">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">image</mat-icon>
            Logo
          </ng-template>
          <div style="padding: 10px!important;">

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">

              <div fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="start center">

                <button mat-flat-button style="margin-right: 10px!important;" (click)="fileuploadInput.click()" color="primary" title="Upload report logo">
                  <i class="material-icons">attach_file</i> HTML Report logo
                  <input type="file" #fileuploadInput id="file" accept="image/*" (change)="importlogo($event.target)" name="myfiles" style="display:none;">
                </button>

                <img *ngIf="advlogo_saved" src="{{advlogo_saved}}" width="100px">
                <div [innerHTML]="uploadlogoprev"></div>

                <button *ngIf="advlogo_saved || advlogo" mat-icon-button color="warn" (click)="clearlogo()" title="Remove report logo">
                  <mat-icon>delete_forever</mat-icon>
                </button>

                <div fxLayout="column" style="margin-top: 5px; height: 125px;">
                  <mat-form-field appearance="outline" color="accent" [style.width.px]="75">
                    <mat-label>Width (px)</mat-label>
                    <input matInput [(ngModel)]="decryptedReportDataChanged.report_settings.report_logo.width" value="{{decryptedReportDataChanged.report_settings.report_logo.width}}">
                  </mat-form-field>
                  
                  <mat-form-field appearance="outline" color="accent" [style.width.px]="75">
                    <mat-label>Height (px)</mat-label>
                    <input matInput [(ngModel)]="decryptedReportDataChanged.report_settings.report_logo.height" value="{{decryptedReportDataChanged.report_settings.report_logo.height}}">
                  </mat-form-field>
                </div>

              </div>
      
            </div>

          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">palette</mat-icon>
            Theme colors
          </ng-template>
          <div style="padding: 10px!important;">
            <mat-form-field appearance="fill" color="accent">
              <mat-label>HTML theme colors:</mat-label>
              <mat-select (selectionChange)="themechange($event)" [(value)]="selectedtheme">
                <mat-option value="white">White (default)</mat-option>
                <mat-option value="dark">Dark</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">tune</mat-icon>
            Other settings
          </ng-template>
          <div style="padding: 10px!important;">

            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">

              <div fxFlex="25%" fxFlex.sm="100%" fxFlex.xs="100%">
                <mat-checkbox [checked]="decryptedReportDataChanged.report_settings.report_video_embed" (change)="embedVideo($event)">Embed video in report<br>(MP4, WebM, and OGG)</mat-checkbox>
              </div>
              <div fxFlex="25%" fxFlex.sm="100%" fxFlex.xs="100%">
                <mat-checkbox [checked]="decryptedReportDataChanged.report_settings.report_parsing_desc" (change)="parsingdescnewline($event)">(Parsing: Description)<br>Add new line</mat-checkbox>
              </div>
              <div fxFlex="25%" fxFlex.sm="100%" fxFlex.xs="100%">
                <mat-checkbox [checked]="decryptedReportDataChanged.report_settings.report_changelog_page" (change)="removechangelogpage($event)">Remove Changelog</mat-checkbox><br>
                <mat-checkbox [checked]="decryptedReportDataChanged.report_settings.report_remove_issuestatus" (change)="removeIssuestatus($event)">Remove Issue status</mat-checkbox><br>
                <mat-checkbox [checked]="decryptedReportDataChanged.report_settings.report_remove_issuecvss" (change)="removeIssuecvss($event)">Remove Issue CVSS</mat-checkbox><br>
                <mat-checkbox [checked]="decryptedReportDataChanged.report_settings.report_remove_issuecve" (change)="removeIssuecve($event)">Remove Issue CVE</mat-checkbox><br>
                <mat-checkbox [checked]="decryptedReportDataChanged.report_settings.report_remove_researchers" (change)="removeResearchers($event)">Remove Researcher</mat-checkbox>
              </div>
      
              <div fxFlex="25%" fxFlex.sm="100%" fxFlex.xs="100%">
                <mat-checkbox [checked]="decryptedReportDataChanged.report_settings.report_remove_issuetags" (change)="removetagsfromreport($event)">Remove TAGs</mat-checkbox>
                <mat-checkbox [checked]="decryptedReportDataChanged.report_settings.report_remove_lastpage" (change)="removeGeninfo($event)">Remove Last page<br>(Generated by VULNRΞPO)</mat-checkbox>
              </div>
      
            </div>

          </div>
        </mat-tab>

      </mat-tab-group>
      


    </mat-card-content>
  </mat-card>
  <br>

  <!-- Download -->
  <mat-card *ngIf="decryptedReportDataChanged">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>cloud_download</mat-icon>
      </div>
      <mat-card-title>Download</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div fxLayout="row wrap" fxLayoutGap="10px grid">

        <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">

          <div fxLayout="row wrap" fxLayoutGap="10px grid">

            <div fxFlex="7%" fxFlex.sm="7%" fxFlex.xs="7%">
              <mat-icon title="Encrypted report" class="vertical-align-middle padding-bottom-3">enhanced_encryption</mat-icon>
            </div>
        
            <div fxFlex="60%" fxFlex.sm="80%" fxFlex.xs="100%">
              <button style="width: 100%;" class="mat-elevation-z9" mat-raised-button color="accent"
              (click)="shareReport(report_info.report_id)">
              <mat-icon class="vertical-align-middle padding-bottom-3">share</mat-icon> Share encrypted
            </button>
            </div>
        
        </div>


      </div>

        <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">

            <div fxLayout="row wrap" fxLayoutGap="10px grid">

              <div fxFlex="7%" fxFlex.sm="7%" fxFlex.xs="7%">
                <mat-icon title="Unencrypted report" class="vertical-align-middle padding-bottom-3">no_encryption</mat-icon>
              </div>

              <div fxFlex="60%" fxFlex.sm="40%" fxFlex.xs="100%">

              <button mat-raised-button style="width: 100%;" color="warn" [matMenuTriggerFor]="aboveMenu"><mat-icon class="vertical-align-middle padding-bottom-3">file_download</mat-icon> Download decrypted</button>
              <mat-menu #aboveMenu="matMenu" yPosition="above">
                <button mat-menu-item (click)="DownloadHTML(decryptedReportDataChanged,report_info,issueStatus)"><mat-icon>description</mat-icon>HTML</button>
                <button mat-menu-item (click)="downloadASCII(decryptedReportDataChanged,report_info)"><mat-icon>text_format</mat-icon>TEXT</button>
              </mat-menu>

              </div>

          
          </div>



        </div>



      </div>

    </mat-card-content>
  </mat-card>

  <br>
  <br>
</div>
<br>