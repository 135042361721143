
<h1 class="mat-display">
    <mat-icon class="vertical-align-middle size-45">sync_problem</mat-icon> API ERROR
</h1>


<div md-dialog-content>
    Cannot connect to the API server!
</div>
<br>
<div md-dialog-actions>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">
    <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="start end">
      <button mat-flat-button color="accent" (click)="savelocally()"><mat-icon>check</mat-icon> Save locally</button>
    </div>
    <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="end start">
      <button mat-flat-button color="primary" (click)="tryagain()"><mat-icon>cancel</mat-icon> Try again</button>
    </div>
  </div>
</div>