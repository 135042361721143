<h1 class="mat-display-2">
  <mat-icon class="vertical-align-middle padding-bottom-3 size-45">settings</mat-icon> Settings
</h1>

<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">

  <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar class="vertical-align-middle padding-bottom-3 size-45">restore</mat-icon>
        <mat-card-title>Backup & Import</mat-card-title>
        <mat-card-subtitle>Save or import saved "My Reports"</mat-card-subtitle>
      </mat-card-header>

      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="dumpallmyreports()">
          <mat-icon class="vertical-align-middle padding-bottom-3">file_download</mat-icon> Backup
        </button>
        <button mat-raised-button type="button" color="primary" (click)="myreportimporall.click()">
          <mat-icon class="vertical-align-middle padding-bottom-3">playlist_add</mat-icon> Import
        </button>
        <input hidden type="file" accept=".txt" #myreportimporall (change)="restoreMyReports($event.target)"/>
      </mat-card-actions>

    </mat-card>
<br>
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar class="vertical-align-middle padding-bottom-3 size-45">delete_forever</mat-icon>
        <mat-card-title>Remove all stored data</mat-card-title>
        <mat-card-subtitle>Wipe all stored data in browser: reports and settings</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">
          <div fxFlex="90%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="start end">
            Are you sure you want to delete all data, this is <b style="margin-left:5px;color: red"> irreversible decision </b>!?
          </div>
          <div fxFlex="10%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="end start">
            <mat-checkbox [(ngModel)]="wipeall">YES</mat-checkbox>
          </div>
        </div>

        
      </mat-card-content>
      <mat-card-actions>
        <button *ngIf="wipeall == true" mat-raised-button color="warn" (click)="wipealldata()"><mat-icon>clear</mat-icon> Yes, do it!</button>
      </mat-card-actions>
    </mat-card>

  </div>
  <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">

    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar class="vertical-align-middle padding-bottom-3 size-45">settings_applications</mat-icon>
        <mat-card-title>Report Profiles</mat-card-title>
        <mat-card-subtitle>Save your settings for report</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <table mat-table *ngIf="reportProfileList.length > 0" style="width: 100%;" [dataSource]="ReportProfilesdataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="profile_name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.profile_name}} </td>
          </ng-container>
        
          <ng-container matColumnDef="profile_settings">
            <th mat-header-cell *matHeaderCellDef> <mat-icon title="Settings">settings</mat-icon> </th>
            <td mat-cell *matCellDef="let element">

              <button mat-icon-button style="width: 25px; height: 25px; flex-shrink: 0; line-height: 5px; border-radius: 70%;" [matMenuTriggerFor]="reportprofilesettings"><mat-icon>more_vert</mat-icon></button>
              <mat-menu #reportprofilesettings="matMenu">
                <a [routerLink]="[]" (click)="editProfileItem(element)" class="active-link" title="Edit" mat-menu-item><mat-icon class="vertical-align-middle">edit</mat-icon> Edit profile</a>
                <a [routerLink]="[]" (click)="removeProfileItem(element)" class="active-link" title="Remove" mat-menu-item><mat-icon class="vertical-align-middle">delete_forever</mat-icon> Remove</a>
              </mat-menu>

            </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="ReportProfilesdisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: ReportProfilesdisplayedColumns;"></tr>
        </table>

      </mat-card-content>
      <mat-card-actions>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">
          <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="start end">
            <button mat-raised-button color="primary" (click)="openDialogReportProfiles('open')" title="Add report profile">
              <mat-icon class="vertical-align-middle padding-bottom-3">add_circle</mat-icon> Add
            </button>
          </div>
          <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="end start">
            <button mat-raised-button color="primary"(click)="imreportprofiles.click()" title="Import report profile">
              <mat-icon class="vertical-align-middle padding-bottom-3">playlist_add</mat-icon> Import
            </button>
            <button *ngIf="reportProfileList.length > 0" mat-raised-button color="primary" (click)="exportprofiles()" title="Export report profiles">
              <mat-icon class="vertical-align-middle padding-bottom-3">file_download</mat-icon> Export
            </button>
            <input hidden type="file" accept=".txt" #imreportprofiles (change)="importReportProfile($event.target)"/>
          </div>
        </div>

      </mat-card-actions>
        
    </mat-card>

  </div>

</div>
<br>
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">
  <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">

  </div>
</div>

<div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">
    <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
      <mat-card>
        <mat-card-header>
          <mat-icon mat-card-avatar class="vertical-align-middle padding-bottom-3 size-45">public</mat-icon>
          <mat-card-title>VULNRΞPO API VAULT ACCESS</mat-card-title>
          <mat-card-subtitle>Save your reports on external API</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <div *ngIf="listkey">
            <p>Found existing vault access!
              <button mat-icon-button color="warn" (click)="removeapikey()" title="Remove current Vault">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </p>
        </div>
        <div fxLayout="row" fxLayoutGap="10px grid">
          <div fxFlex="50%" fxLayoutAlign="start">
            <button *ngIf="!apiconneted && !tryconnectdb" mat-raised-button color="accent" (click)="openDialogAPIADD()"><mat-icon class="vertical-align-middle padding-bottom-3">cloud_upload</mat-icon> Add API</button>
            <button *ngIf="tryconnectdb" mat-raised-button color="accent" (click)="tryconnect()"><mat-icon class="vertical-align-middle padding-bottom-3">lock_open</mat-icon> Access to Vault</button> <br><br>
            <button *ngIf="apiconneted" mat-raised-button color="warn" (click)="apidisconnect()">Disconnect</button>
  
          </div>
          <div fxFlex="50%" fxLayoutAlign="end end">
            <button *ngIf="apiconneted || tryconnectdb" mat-raised-button color="primary" (click)="exportvault()"><mat-icon class="vertical-align-middle padding-bottom-3">file_download</mat-icon> Export Vault</button>&nbsp;&nbsp;
            <button type="button" *ngIf="!apiconneted && !tryconnectdb" mat-raised-button color="primary" (click)="VaultFileInput.click()"><mat-icon class="vertical-align-middle padding-bottom-3">playlist_add</mat-icon> Import Vault</button>
            <input hidden type="file" accept=".json" #VaultFileInput (change)="importvault($event.target)"/>
          </div>
        </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <br>
  <mat-card *ngIf="!tryconnectdb && !showregapi">
    <h3>
      VULNRΞPO API List&nbsp;
      <button mat-icon-button style="width: 25px; height: 25px; flex-shrink: 0; line-height: 5px; border-radius: 70%;" [matMenuTriggerFor]="apimenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #apimenu="matMenu">
          <a [routerLink]="[]" (click)="openDialogAPIaddtovault()" class="active-link" title="Add API" mat-menu-item>
            <mat-icon class="vertical-align-middle padding-bottom-3">add_circle</mat-icon> Add API
          </a>
          <a target="_blank" class="active-link" rel="noopener noreferrer" href="https://github.com/kac89/vulnrepo/blob/master/API-INTEGRATION.md" mat-menu-item><mat-icon class="vertical-align-middle padding-bottom-3">build</mat-icon> Build own API</a>
      </mat-menu>
    </h3>

<div>

  <div *ngIf="dataSource.data.length == 0" style="padding: 30px 0px 0px 20px; width: 500px;">
    Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  </div>

  <table mat-table *ngIf="dataSource.data.length > 0" style="width: 100%;" [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="apiname">
      <th mat-header-cell *matHeaderCellDef> API Name </th>
      <td mat-cell *matCellDef="let element"> <a target="_blank" class="active-link" rel="noopener noreferrer" href="https://{{element.apiurl}}/">{{element.apiname}}</a> </td>
    </ng-container>
  
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.status == 'Connected'" color="accent" class="vertical-align-middle">check_circle</mat-icon>
        <mat-icon *ngIf="element.status == 'Not connected'" color="warn" class="vertical-align-middle">warning</mat-icon>
        <mat-icon *ngIf="element.status == 'Not connected: wrong API key?'" color="warn" class="vertical-align-middle">warning</mat-icon> 
        {{element.status}}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.created}} </td>
    </ng-container>

    <ng-container matColumnDef="expires">
      <th mat-header-cell *matHeaderCellDef> Expires </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.expires == '0 (NaN days left)'">
          <span class="material-icons">all_inclusive</span>
        </span>
        <span *ngIf="element.expires != '0 (NaN days left)'">{{element.expires}} </span>
       </td>
    </ng-container>

    <ng-container matColumnDef="storage">
      <th mat-header-cell *matHeaderCellDef> Storage </th>
        <td mat-cell *matCellDef="let element">
            <mat-progress-bar 
            title="Storage used: {{element.current_storage}}%"
            color="warn"
            mode="determinate"
            value="{{element.current_storage}}"
            bufferValue="100">
        </mat-progress-bar>
        </td>
    </ng-container>

    <ng-container matColumnDef="settings">
      <th mat-header-cell *matHeaderCellDef> <mat-icon title="Settings">settings</mat-icon> </th>
      <td mat-cell *matCellDef="let element"> <a [routerLink]="[]" (click)="removeApi(element.apikey)" title="Remove"><mat-icon color="warn" class="vertical-align-middle">delete_forever</mat-icon></a> </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
  

</div>


    </mat-card>

</div>

<br>
<br>
<br>
