
<h1 class="mat-display">
    <mat-icon class="vertical-align-middle size-45">https</mat-icon> Decrypt report
</h1>


<div md-dialog-content>

  <form class="example-form">

    <mat-form-field class="example-full-width" color="accent">
      <input matInput placeholder="Report security key" #pass (keydown)="onKeydown($event,pass.value,data.report_id)" [type]="hide ? 'password' : 'text'">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>

    <div class="example-full-width" fxLayout="row" fxLayoutGap="10px grid">
      <div fxFlex="65%" fxLayoutAlign="flex-start center">
        <p *ngIf="error != ''">
          <span class="label danger"><mat-icon class="vertical-align-middle padding-bottom-3 size-25">warning</mat-icon> {{error}}</span><br>
        </p>
      </div>
      <div fxFlex="35%" fxLayoutAlign="flex-end center">
        <div *ngIf="forgot">
          <a class="active-link" target="_blank" href="/faq?q=I-forgot-my-security-key">Forgot your key?</a>
        </div>
      </div>
    </div>

    <div *ngIf="msg != ''">
      <p>{{msg}}<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></p>
    </div>
  </form>

</div>
<br>
<div md-dialog-actions>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">
    <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="start end">
      <button mat-flat-button color="accent" (click)="decrypt(pass.value,data.report_id)"><mat-icon>check</mat-icon> Confirm</button>
    </div>
    <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="end start">
      <button mat-flat-button color="primary" (click)="cancel()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
  </div>
</div>