<h1 mat-dialog-title>Add Issue from: 
<mat-form-field appearance="outline" color="accent" style="width: 150px; font-size:11px;">
  <mat-label>Source</mat-label>
  <mat-select [(value)]="sourceSelect" (selectionChange)="changeselect()">
    <mat-option value="VULNREPO">VULNRΞPO</mat-option>
    <mat-option value="CVE">CVE (ONLINE DB)</mat-option>
    <mat-option value="CWE">CWE Research Concepts</mat-option>
    <mat-option value="MENTERPRISE">MITRE ATT&CK Enterprise</mat-option>
    <mat-option value="MMOBILE">MITRE ATT&CK Mobile</mat-option>
    <mat-option value="PCIDSS">PCI DSS Requirements v3.2.1</mat-option>
    <mat-option value="OWASPTOP2021">OWASP Top 10 2021 Web Application Security Risks</mat-option>
    <mat-option value="OWASPTOP2017">OWASP Top 10 2017 Web Application Security Risks</mat-option>
  </mat-select>
</mat-form-field>
</h1>
<div mat-dialog-content>

  <div *ngIf="sourceSelect == 'VULNREPO'" class="example-form">
      <mat-form-field class="example-form" color="accent" appearance="outline">
        <mat-label>Issue title</mat-label>
        <input cdkFocusInitial type="text" matInput placeholder="type: XSS, SQL, RCE vulnerability or custom" [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.title">
              {{option.title}}
            </mat-option>
          </mat-autocomplete>
      </mat-form-field>
      <span fxLayoutAlign="flex-end"><small><a class="active-link" href="/vuln-list" routerLink="/vuln-list" (click)="redir()">Vulnerability List</a></small></span>
  </div>
  

  <div *ngIf="sourceSelect == 'CWE'" class="example-form">
    <mat-form-field class="example-form" color="accent" appearance="outline">
      <mat-label>CWE title</mat-label>
      <input cdkFocusInitial type="text" matInput placeholder="type: CWE-20 or bypass, injection" [formControl]="myControl2" [matAutocomplete]="auto2">
      <mat-autocomplete #auto2="matAutocomplete">
          <mat-option [title]="option.title" *ngFor="let option of filteredOptionsCWE | async" [value]="option.title">
            {{option.title}}
          </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <span fxLayoutAlign="flex-end"><small><a class="active-link" target="_blank" href="https://cwe.mitre.org/data/index.html">cwe.mitre.org</a></small></span>
</div>


  <div *ngIf="sourceSelect == 'CVE'" class="example-form">
    <mat-form-field class="example-form" color="accent" appearance="outline">
      <mat-label>CVE</mat-label>
      <input cdkFocusInitial type="text" matInput placeholder="e.g.: CVE-2016-0077" [formControl]="mycve">
    </mat-form-field>
    <span fxLayoutAlign="flex-end"><small><a class="active-link" target="_blank" href="https://cve.circl.lu/">cve.circl.lu</a></small></span>
</div>

<div *ngIf="sourceSelect == 'MMOBILE'" class="example-form">
  <mat-form-field class="example-form" color="accent" appearance="outline">
    <mat-label>ATT&CK Mobile</mat-label>
    <input cdkFocusInitial type="text" matInput placeholder="e.g.: Application Discovery" [formControl]="mymobilemitre" [matAutocomplete]="auto3">
    <mat-autocomplete #auto3="matAutocomplete">
        <mat-option [title]="option.title" *ngFor="let option of filteredOptionsmitremobile | async" [value]="option.title">
          {{option.title}}
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>
  <span fxLayoutAlign="flex-end"><small><a class="active-link" target="_blank" href="https://attack.mitre.org/techniques/mobile/">attack.mitre.org</a></small></span>
</div>

<div *ngIf="sourceSelect == 'MENTERPRISE'" class="example-form">
  <mat-form-field class="example-form" color="accent" appearance="outline">
    <mat-label>ATT&CK Enterprise</mat-label>
    <input cdkFocusInitial type="text" matInput placeholder="e.g.: DNS Server" [formControl]="myenterprisemitre" [matAutocomplete]="auto4">
    <mat-autocomplete #auto4="matAutocomplete">
        <mat-option [title]="option.title" *ngFor="let option of filteredOptionsmitreenterprise | async" [value]="option.title">
          {{option.title}}
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>
  <span fxLayoutAlign="flex-end"><small><a class="active-link" target="_blank" href="https://attack.mitre.org/techniques/enterprise/">attack.mitre.org</a></small></span>
</div>

<div *ngIf="sourceSelect == 'PCIDSS'" class="example-form">
  <mat-form-field class="example-form" color="accent" appearance="outline">
    <mat-label>PCI DSS Requirements v3.2.1</mat-label>
    <input cdkFocusInitial type="text" matInput placeholder="e.g.: Firewall" [formControl]="myPCI" [matAutocomplete]="auto5">
    <mat-autocomplete #auto5="matAutocomplete">
      <mat-optgroup *ngFor="let group of filteredOptionsPCIDSS | async" [label]="group.maincategory" [title]="group.maincategory">
        <mat-option *ngFor="let item of group.items" [value]="item.title" [title]="item.title">
          {{item.title}}
        </mat-option>
    </mat-optgroup>
      </mat-autocomplete>
  </mat-form-field>
  <span fxLayoutAlign="flex-end"><small><a class="active-link" target="_blank" href="https://www.pcisecuritystandards.org/document_library">pcisecuritystandards.org</a></small></span>
</div>

<div *ngIf="sourceSelect == 'OWASPTOP2017'" class="example-form">
  <mat-form-field class="example-form" color="accent" appearance="outline">
    <mat-label>OWASP category</mat-label>
    <input cdkFocusInitial type="text" matInput placeholder="type: Injection" [formControl]="myOWASP2017" [matAutocomplete]="auto6">
    <mat-autocomplete #auto6="matAutocomplete">
      <mat-option [title]="option.title" *ngFor="let option of filteredOptionsOWASPtop2017 | async" [value]="option.title">
        {{option.title}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <span fxLayoutAlign="flex-end"><small><a class="active-link" target="_blank" href="https://owasp.org/www-project-top-ten/">owasp.org</a></small></span>
</div>

<div *ngIf="sourceSelect == 'OWASPTOP2021'" class="example-form">
  <mat-form-field class="example-form" color="accent" appearance="outline">
    <mat-label>OWASP category</mat-label>
    <input cdkFocusInitial type="text" matInput placeholder="type: Injection" [formControl]="myOWASP2021" [matAutocomplete]="auto7">
    <mat-autocomplete #auto7="matAutocomplete">
      <mat-option [title]="option.title" *ngFor="let option of filteredOptionsOWASPtop2021 | async" [value]="option.title">
        {{option.title}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <span fxLayoutAlign="flex-end"><small><a class="active-link" target="_blank" href="https://owasp.org/www-project-top-ten/">owasp.org</a></small></span>
</div>

<div *ngIf="show">Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar></div>
    {{err_msg}}
</div>
<div mat-dialog-actions>
  <button *ngIf="sourceSelect == 'VULNREPO'" mat-raised-button color="accent" (click)="addIssue()"><mat-icon class="vertical-align-middle padding-bottom-3">add</mat-icon> Add Issue</button>
  <button *ngIf="sourceSelect == 'CWE'" mat-raised-button color="accent" (click)="addIssueCWE()"><mat-icon class="vertical-align-middle padding-bottom-3">add</mat-icon> Add from CWE</button>
  <button *ngIf="sourceSelect == 'CVE'" mat-raised-button color="accent" (click)="addCVE()"><mat-icon class="vertical-align-middle padding-bottom-3">add</mat-icon> Add from CVE</button>
  <button *ngIf="sourceSelect == 'MMOBILE'" mat-raised-button color="accent" (click)="addattackMobile()"><mat-icon class="vertical-align-middle padding-bottom-3">add</mat-icon> Add from ATT&CK Mobile</button>
  <button *ngIf="sourceSelect == 'MENTERPRISE'" mat-raised-button color="accent" (click)="addattackEnterprise()"><mat-icon class="vertical-align-middle padding-bottom-3">add</mat-icon> Add from ATT&CK Enterprise</button>
  <button *ngIf="sourceSelect == 'PCIDSS'" mat-raised-button color="accent" (click)="addPCIDSS()"><mat-icon class="vertical-align-middle padding-bottom-3">add</mat-icon> Add from PCI DSS Requirements</button>
  <button *ngIf="sourceSelect == 'OWASPTOP2017'" mat-raised-button color="accent" (click)="addOWASPtop2017()"><mat-icon class="vertical-align-middle padding-bottom-3">add</mat-icon> Add OWASP Top 10 2017</button>
  <button *ngIf="sourceSelect == 'OWASPTOP2021'" mat-raised-button color="accent" (click)="addOWASPtop2021()"><mat-icon class="vertical-align-middle padding-bottom-3">add</mat-icon> Add OWASP Top 10 2021</button>
  &nbsp;
  <button mat-raised-button color="primary" (click)="cancel()">Cancel</button>
</div>




