<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
      <mat-icon>menu</mat-icon>
    </button>
    <span style="display: contents;">
      <img style="margin-right: 5px;" width="35px" src="assets/logo/vulnrepo_logo.png"> <span style="color:white">VULNRΞPO</span>&nbsp;<small>v.β <small *ngIf="app_ver != ''"><span style="text-decoration: underline;text-decoration-color: #69f0ae"><a title="Version Rev ID: {{app_ver}}" target="_blank" style="font-size: smaller; color: rgba(255, 255, 255, 0.7);" href="https://github.com/kac89/vulnrepo/commit/{{app_ver}}">#{{app_ver_short}}</a></span></small></small>
    </span>
    <div *ngIf="show_status" class="title-center">

      <div *ngIf="enc_status">
        <mat-icon class="vertical-align-middle padding-bottom-3 decrypted-color" matSuffix>no_encryption</mat-icon> Report is decrypted.
      </div>
      <div *ngIf="!enc_status"><mat-icon class="vertical-align-middle padding-bottom-3 encrypted-color" matSuffix>lock</mat-icon> Report is encrypted.</div>

    </div>
    
    <span class="example-fill-remaining-space" *ngIf="!show_status"></span>

    <div fxShow="true" fxHide.lt-md="true">
      <a routerLink="/home" routerLinkActive="active-link" href="/home" mat-button>
        <mat-icon class="vertical-align-middle padding-bottom-3">home</mat-icon>
        Home
      </a>

      <a routerLink="/my-reports" routerLinkActive="active-link" href="/my-reports" mat-button>
        <mat-icon class="vertical-align-middle padding-bottom-3">list_alt</mat-icon>
        My Reports
      </a>

      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">

        <a routerLink="/new-report" routerLinkActive="active-link" href="/new-report" mat-menu-item>
          <mat-icon class="vertical-align-middle padding-bottom-3">add_circle_outline</mat-icon> Create new report
        </a>

        <a routerLink="/import-report" routerLinkActive="active-link" href="/import-report" mat-menu-item>
          <mat-icon class="vertical-align-middle padding-bottom-3">playlist_add</mat-icon> Import report
        </a>

        <a routerLink="/vuln-list" routerLinkActive="active-link" href="/vuln-list" mat-menu-item>
          <mat-icon class="vertical-align-middle padding-bottom-3">bug_report</mat-icon>
          Vulnerability List
        </a>
        <a routerLink="/settings" routerLinkActive="active-link" href="/settings" mat-menu-item>
          <mat-icon class="vertical-align-middle padding-bottom-3">settings</mat-icon>
          Settings
        </a>
        <a routerLink="/faq" routerLinkActive="active-link" href="/faq" mat-menu-item>
          <mat-icon class="vertical-align-middle padding-bottom-3">info</mat-icon>
          FAQ
        </a>
      </mat-menu>

      <a target="_blank" href="https://github.com/kac89/vulnrepo" title="Visit project on github.com" mat-button>
        <img src="./assets/github.png" height="22px">
      </a>

      <a target="_blank" href="https://twitter.com/vulnrepo" title="Follow project on twitter" mat-button>
        <img src="./assets/twitter.svg" height="32px">
      </a>

    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container fxFlexFill>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <a routerLink="/home" routerLinkActive="active-link" href="/home" mat-list-item>
        <mat-icon class="vertical-align-middle padding-bottom-3">home</mat-icon>
        Home
      </a>
      <a routerLink="/my-reports" routerLinkActive="active-link" href="/my-reports" mat-list-item>
        <mat-icon class="vertical-align-middle padding-bottom-3">list_alt</mat-icon>
        My Reports
      </a>
      <a routerLink="/new-report" routerLinkActive="active-link" href="/new-report" mat-list-item>
        <mat-icon class="vertical-align-middle padding-bottom-3">add_circle_outline</mat-icon> Create new report
      </a>
      <a routerLink="/import-report" routerLinkActive="active-link" href="/import-report" mat-list-item>
        <mat-icon class="vertical-align-middle padding-bottom-3">playlist_add</mat-icon> Import report
      </a>
      <a routerLink="/vuln-list" routerLinkActive="active-link" href="/vuln-list" mat-list-item>
        <mat-icon class="vertical-align-middle padding-bottom-3">bug_report</mat-icon>
        Vulnerability List
      </a>
      <a routerLink="/settings" routerLinkActive="active-link" href="/settings" mat-list-item>
        <mat-icon class="vertical-align-middle padding-bottom-3">settings</mat-icon>
        Settings
      </a>
      <a routerLink="/faq" routerLinkActive="active-link" href="/faq" mat-list-item>
        <mat-icon class="vertical-align-middle padding-bottom-3">info</mat-icon>
        FAQ
      </a>
      <a (click)="sidenav.toggle()" mat-list-item>
        <mat-icon>close</mat-icon> Close
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content fxFlexFill>

    <div class="container">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>