
<h1 class="mat-display">
    Report Settings Profile
</h1>

<div md-dialog-content>

  <mat-form-field class="example-full-width" color="accent">
    <mat-label>Profile name (*)</mat-label>
    <input matInput placeholder="Ex. My bugbounty settings profile" [formControl]="profile_name" value="" />
  </mat-form-field>
  
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">

    <div fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayoutAlign="start center">

      <button mat-flat-button (click)="fileuploadInput.click()" color="primary" title="Upload report logo" style="margin-right: 10px!important;">
        <i class="material-icons">attach_file</i> HTML Report logo
        <input type="file" #fileuploadInput id="file" accept="image/*" (change)="importlogo($event.target)" name="myfiles" style="display:none;">
      </button>

      <div fxLayout="column" style="margin-top: 5px; height: 118px;" style="margin-right: 10px!important;">
        <mat-form-field appearance="outline" color="accent" [style.width.px]="85">
          <mat-label>Width (px)</mat-label>
          <input matInput [formControl]="logow">
        </mat-form-field>
        
        <mat-form-field appearance="outline" color="accent" [style.width.px]="85">
          <mat-label>Height (px)</mat-label>
          <input matInput [formControl]="logoh">
        </mat-form-field>
      </div>

      <div [innerHTML]="uploadlogoprev"></div>

      <button *ngIf="advlogo" mat-icon-button color="warn" (click)="clearlogo()" title="Remove report logo">
        <mat-icon>delete_forever</mat-icon>
      </button>

    </div>

  </div>

  <br>

  <mat-form-field class="example-full-width" color="accent">
      <mat-label>Theme</mat-label>
      <mat-select [formControl]="profile_theme">
        <mat-option value="white">White</mat-option>
        <mat-option value="dark">Dark</mat-option>
      </mat-select>
    </mat-form-field>


  <div class="example-full-width">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">

      <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
        <mat-checkbox [formControl]="video_embed">Embed video in report<br>(MP4, WebM, and OGG)</mat-checkbox>
        <mat-checkbox [formControl]="remove_lastpage">Remove Last page<br>(Generated by VULNRΞPO)</mat-checkbox>
      </div>

      <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
        <mat-checkbox [formControl]="report_parsing_desc">(Parsing: Description)<br>Add new line</mat-checkbox>
        <mat-checkbox [formControl]="remove_changelog">Remove Changelog</mat-checkbox>
        <mat-checkbox [formControl]="remove_issueStatus">Remove Issue status</mat-checkbox>
        <mat-checkbox [formControl]="remove_issuecvss">Remove Issue CVSS</mat-checkbox>
        <mat-checkbox [formControl]="remove_issuecve">Remove Issue CVE</mat-checkbox>
        <mat-checkbox [formControl]="remove_researcher">Remove Researcher</mat-checkbox>
        <mat-checkbox [formControl]="remove_tags">Remove TAGs</mat-checkbox>
      </div>

    </div>

  </div>
  <hr>
  <div class="example-full-width">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px grid">

      <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
        <mat-form-field class="example-full-width" color="accent">
          <input matInput placeholder="Name" [formControl]="ResName">
          <mat-icon matSuffix>person_pin</mat-icon>
        </mat-form-field>

        <mat-form-field class="example-full-width" color="accent">
          <input matInput placeholder="Social links" [formControl]="ResSocial">
          <mat-icon matSuffix>share</mat-icon>
        </mat-form-field>
      </div>

      <div fxFlex="50%" fxFlex.sm="100%" fxFlex.xs="100%">
        <mat-form-field class="example-full-width" color="accent">
          <input matInput placeholder="E-mail" [formControl]="ResEmail">
          <mat-icon matSuffix>email</mat-icon>
        </mat-form-field>

        <mat-form-field class="example-full-width" color="accent">
          <input matInput placeholder="Website" [formControl]="ResWeb">
          <mat-icon matSuffix>public</mat-icon>
        </mat-form-field>
      </div>

    </div>

  </div>
      

  
</div>
<br>
<div md-dialog-actions>
  <div>
    <button mat-flat-button color="accent" *ngIf="data == 'open'" (click)="addNewProfile()">Add</button>
    <button mat-flat-button color="accent" *ngIf="data !== 'open'" (click)="saveNewProfile()">Save</button>
    &nbsp;
    <button mat-flat-button color="primary" (click)="cancel()">Cancel</button>
  </div>
</div>